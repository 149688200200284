<template>
  <v-container fluid :class="desktop ? 'px-10' : null">
    <!-- Button (Add) -->
    <create-transport-btn @click.native="drawer = !drawer" v-if="!desktop" />

    <!-- Header -->
    <v-row align="center" v-if="desktop">
      <v-col>
        <v-list-item two-line class="px-0">
          <v-list-item-content>
            <v-list-item-subtitle>Home / Staff</v-list-item-subtitle>
            <v-list-item-title class="text-h5 | font-weight-medium">
              Transporte Autorizado
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col class="text-end" v-if="desktop">
        <v-btn @click="drawer = true" color="primary">Crear Conductor</v-btn>
      </v-col>
    </v-row>

    <!-- Table (Reader) -->
    <v-row>
      <v-col>
        <v-data-table :headers="headers" :items="staff" :items-per-page="10">
          <!-- Action -->
          <template v-slot:[`item.actions`]="{ item }">
            <div class="text-end | pr-4">
              <!-- <v-icon small @click="updateItem(item)"> mdi-pencil </v-icon> -->
              <v-icon class="ml-3" small @click="deleteItem(item)">
                mdi-delete
              </v-icon>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Create (Item) -->
    <create-transport
      v-model="drawer"
      :edit="edit"
      :item="item"
      @input="(edit = false), (item = null)"
    />
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";

import CreateTransport from "./Create.vue";
import CreateTransportBtn from "../../components/ui/mobile/AppBarAdd.vue";

export default {
  components: { CreateTransport, CreateTransportBtn },

  data: () => ({
    item: null,
    edit: false,
    drawer: false,
    headers: [
      { text: "Nombre", value: "name" },
      { text: "Rut", value: "rut" },
      { text: "Eliminar", value: "actions", class: "text-end" },
    ],
  }),

  computed: {
    ...mapState(["desktop"]),
    ...mapState("staff", ["staff"]),
  },

  methods: {
    ...mapActions("staff", ["reader", "delete"]),

    updateItem(value) {
      this.edit = true;
      this.item = value;
      this.drawer = true;
    },

    deleteItem(value) {
      this.delete(value.docId);
    },
  },

  async created() {
    this.loading = true;
    await this.reader();
    this.loading = false;
  },

  beforeCreate() {
    this.$root.$emit("app-bar", "Transporte", 0);
  },

  beforeDestroy() {
    this.$store.dispatch("staff/setUnsubscribe");
  },
};
</script>