<template>
  <v-navigation-drawer
    :app="!desktop"
    :value="value"
    :width="desktop ? 400 : '100%'"
    :absolute="desktop"
    :permanent="value"
    :hide-overlay="!desktop"
    temporary
    right
  >
    <!-- Header -->
    <v-toolbar :color="desktop ? 'transparent' : 'primary'" flat>
      <v-toolbar-title :class="desktop ? 'text-subtitle-1' : null">
        {{ edit ? "Editar" : "Crear" }} Transporte
      </v-toolbar-title>
    </v-toolbar>
    <v-divider v-if="desktop" />

    <!-- Content -->
    <v-container class="px-4 pt-6">
      <v-form ref="form">
        <!-- Rut -->
        <v-text-field
          v-model="driverId"
          color="accent"
          class="mb-5"
          :rules="[rules.required]"
          label="RUT Conductor"
          hide-details
          outlined
          dense
        />
        <!-- Nombre -->
        <v-text-field
          v-model="driverName"
          color="accent"
          class="mb-5"
          :rules="[rules.required]"
          label="Nombre Conductor"
          hide-details
          outlined
          dense
        />
        <!-- Patente -->
        <v-text-field
          v-model="diverCarId"
          color="accent"
          class="mb-5"
          label="Patente Vehiculo"
          hide-details
          outlined
          dense
        />
      </v-form>
    </v-container>

    <!-- Footer -->
    <template v-slot:append>
      <div class="pa-4 | d-flex" :class="desktop ? null : 'mb-5'">
        <v-btn :disabled="loading" @click="onCancel" text> Cancelar </v-btn>
        <v-spacer />
        <v-btn
          :disabled="loading"
          :loading="loading"
          @click="onSubmit"
          class="px-8"
          color="primary"
          >Guardar</v-btn
        >
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import Rut from "rutjs";
import { mapActions, mapState } from "vuex";
import { rules } from "@/assets/rules";

export default {
  props: ["value", "edit", "item"],

  data: () => ({
    rules: rules,
    loading: false,
    driverId: "",
    driverName: "",
    diverCarId: "",
  }),

  watch: {
    driverId(value) {
      if (!value) return "";
      let driver = new Rut(String(value));
      this.driverId = driver.getNiceRut(false);
    },

    diverCarId: function (value) {
      if (!value) return "";
      this.diverCarId = String(value).toUpperCase();
    },
  },

  computed: {
    ...mapState(["desktop"]),
  },

  methods: {
    ...mapActions("staff", ["create"]),

    async onSubmit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        await this.create({
          rut: this.driverId,
          name: this.driverName,
          carId: this.diverCarId,
          role: "transport",
        });

        // Finish
        this.onCancel();
        this.loading = false;
      }
    },

    onCancel() {
      this.$emit("input", false);
      this.$refs.form.reset();
    },
  },
};
</script>